import { ScreenRows, Layout } from 'components/_shared/widgets/gallery';

export const GALLERY_SIZE = 7;
export const MAX_GALLERIES_PER_SHOP = 1;

const DESKTOP_TABLET_LAYOUT_1: ScreenRows = [4, 3];
const DESKTOP_TABLET_LAYOUT_2: ScreenRows = [3, 4];
const MOBILE_LAYOUT_1: ScreenRows = [2, 2, 1, 2];

export const LAYOUT_COLLECTION: Layout[] = [
  [MOBILE_LAYOUT_1, DESKTOP_TABLET_LAYOUT_1, DESKTOP_TABLET_LAYOUT_1],
  [MOBILE_LAYOUT_1, DESKTOP_TABLET_LAYOUT_2, DESKTOP_TABLET_LAYOUT_2],
  [[2, 1, 2, 2], DESKTOP_TABLET_LAYOUT_1, DESKTOP_TABLET_LAYOUT_1],
  [[1, 2, 2, 2], DESKTOP_TABLET_LAYOUT_2, DESKTOP_TABLET_LAYOUT_2],
  [[1, 1, 2, 2, 1], DESKTOP_TABLET_LAYOUT_2, DESKTOP_TABLET_LAYOUT_2],
];
