import { Container, Grid } from 'components/box';
import { HomePage } from 'components/_pages/home/types';
import Banner from 'components/_pages/home/banner';
import ProductListing from 'components/_shared/widgets/product-listing';
import dynamic from 'next/dynamic';

const LimitedTimeDeal = dynamic(() => import('components/limited-time-deal'));

const Body = ({ banners, campaignProduct, ...props }: HomePage) => (
  <Container py={3}>
    <Grid>
      {!!banners && <Banner banners={banners} />}
      {!!campaignProduct && (
        <LimitedTimeDeal item={campaignProduct} productSide="right" />
      )}
      <ProductListing {...props} />
    </Grid>
  </Container>
);

export default Body;
