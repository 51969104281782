import Head from 'next/head';
import { SchemaOrganization, SchemaProduct } from './types';
import config from '../../../util/load-config';
import { trimUrl } from '../../../util/url';

/**
 * @see https://schema.org/
 * @see https://developers.google.com/search/docs/appearance/structured-data/
 * @see https://search.google.com/test/rich-results (note the lack of trailing slash, breaks when there is one)
 */
const Schema = ({ schema }: { schema: SchemaOrganization | SchemaProduct }) => (
  <Head>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({ '@context': 'https://schema.org', ...schema }),
      }}
    />
  </Head>
);

export const OrganizationSchema = () => (
  <Schema
    schema={{
      '@type': 'Organization',
      name: config.store.name,
      url: trimUrl(config.site.url),
      logo: `${trimUrl(config.site.url)}/app-logo-192.png`,
      sameAs: [
        config.social.facebookPageUrl,
        config.social.twitterPageUrl,
        config.social.instagramUrl,
      ],
    }}
  />
);

export default Schema;
