import { Flex } from 'components/box';
import Clock from 'icons/clock.svg';
import { Heading } from 'components/typography';

const QueryError = ({
  message = 'There was a problem accessing fresh data',
}: {
  message?: string;
}) => (
  <Flex py={5} mx="auto" flexDirection="column" alignItems="center">
    <Heading textAlign="center" mb={4}>
      {message ||
        'While trying to fetch data for this page, an error occurred. ' +
          'Please refresh the page, or try again after a couple of minutes if the problem persists.'}
    </Heading>

    <Clock />
  </Flex>
);

export default QueryError;
