import { QueryHomePageOutput } from 'types/types';
import { HomePage } from './types';
import {
  GALLERY_SIZE,
  MAX_GALLERIES_PER_SHOP,
  LAYOUT_COLLECTION,
} from './constants';
import { prepProductListing } from 'components/_shared/widgets/product-listing/utils';

/**
 * Data/props preparation.
 */
export const homePageProps: (data: QueryHomePageOutput) => HomePage = ({
  products,
  promoBlocks,
  banners,
  campaignProducts,
}) => ({
  items: prepProductListing({
    products,
    promoBlocks,
    withHero: true,
    withShops: true,
    gallerySize: GALLERY_SIZE,
    maxGalleriesPerShop: MAX_GALLERIES_PER_SHOP,
    galleryLayouts: LAYOUT_COLLECTION,
  }),
  banners: banners ?? null,
  campaignProduct: campaignProducts ? campaignProducts.shift() || null : null,
});
